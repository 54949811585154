import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Face.styles';

interface Props extends WithStyles<typeof styles> {
  color?: string;
}

const FacePendingIconComponent: FC<Props> = ({ classes, color = '#DC6803' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M10 8.5V12.5L12.5 14M10 4C5.30558 4 1.5 7.80558 1.5 12.5C1.5 17.1944 5.30558 21 10 21C14.6944 21 18.5 17.1944 18.5 12.5C18.5 7.80558 14.6944 4 10 4ZM10 4V1M8 1H12M18.329 4.59204L16.829 3.09204L17.579 3.84204M1.67102 4.59204L3.17102 3.09204L2.42102 3.84204"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const FacePendingIcon = withStyles(styles)(FacePendingIconComponent);
