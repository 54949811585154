import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './AlertTriangle.styles';

interface Props extends WithStyles<typeof styles> {
  color?: string;
}

const AlertTriangleIconComponent: FC<Props> = ({ classes, color = '#B54708' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M5.99988 4.49999V6.49999M5.99988 8.49999H6.00488M5.30754 1.94585L1.19509 9.04917C0.966991 9.44316 0.85294 9.64016 0.869797 9.80184C0.884499 9.94286 0.958384 10.071 1.07306 10.1544C1.20454 10.25 1.43217 10.25 1.88744 10.25H10.1123C10.5676 10.25 10.7952 10.25 10.9267 10.1544C11.0414 10.071 11.1153 9.94286 11.13 9.80184C11.1468 9.64016 11.0328 9.44316 10.8047 9.04916L6.69222 1.94585C6.46493 1.55327 6.35129 1.35698 6.20303 1.29105C6.0737 1.23355 5.92606 1.23355 5.79673 1.29105C5.64846 1.35698 5.53482 1.55327 5.30754 1.94585Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const AlertTriangleIcon = withStyles(styles)(AlertTriangleIconComponent);
